import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Disqus } from 'gatsby-plugin-disqus';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <AuthorBanner mdxType="AuthorBanner"></AuthorBanner>
    <p>&nbsp;</p>
    <Disqus config={{
      url: props.siteMetadata.siteUrl + props.postData.fields.slug,
      title: props.postData.frontmatter.title,
      id: props.postData.id
    }} mdxType="Disqus" />
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      